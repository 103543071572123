export const BASIC_QUESTIONS_ROUTE = 'basic-questions';
export const HEALTH_ROUTE = 'health';
export const QUOTES_ROUTE = 'quotes';

const phaseList = [
    {
        name: 'Intro',
        route: 'basic',
        index: 0,
        hidden: true,
    },
    {
        name: 'Basics',
        route: BASIC_QUESTIONS_ROUTE,
        index: 1,
    },
    {
        name: 'Health',
        route: HEALTH_ROUTE,
        index: 2,
    },
    {
        name: 'Estimate',
        route: QUOTES_ROUTE,
        index: 3,
    },
    {
        name: 'Apply',
        route: '',
        index: 4,
    },
];

export default phaseList;
