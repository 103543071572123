import {IReactionDisposer, makeAutoObservable, reaction, runInAction, toJS} from 'mobx';

import {
    AnySelectedQuote,
    ApprovalData,
    ApprovalRequest,
    ExtendedProductTypeCode,
    IulScrapeQuote,
    Product,
    ProductTypeCode,
    RiderInfo,
} from '@amplify/athena-models';
import {TermScrapeQuote} from '@amplify/athena-models/dist/recommendation/scrapeQuote';

import Phase3Api from '../../api/phases/phase3Api';
import reactionDebounce from '../../helpers/reactionDebounce';
import updateCustomField from '../../helpers/updateCustomField';
import updateField from '../../helpers/updateField';
import {mapStateToEligibleProductsRequestData} from '../CalculatorStoreHelper';
import {type UserData} from '.';
import {serializable} from '../sync/serialization';

const productTypeToDisplayMap = new Map<ExtendedProductTypeCode, string>([
    [ExtendedProductTypeCode.VUL, 'VUL'],
    [ExtendedProductTypeCode.Combo, 'Best'],
    [ExtendedProductTypeCode.IUL, 'Retirement'],
    [ExtendedProductTypeCode.Term, 'Temporary'],
]);

const productTypeToTypeData = new Map<ExtendedProductTypeCode, string>([
    [ExtendedProductTypeCode.VUL, 'vul'],
    [ExtendedProductTypeCode.Combo, 'resultBest'],
    [ExtendedProductTypeCode.IUL, 'resultRetirement'],
    [ExtendedProductTypeCode.Term, 'resultTemporary'],
]);

class Phase3 {
    private parentStore: UserData;

    @serializable()
    temporary: {
        yearCoverage: number;
        coveragePeriod: number;
        defaultCoveragePeriod: number;
        coveragePeriodRange: number[];
        illness: string[];
        // will be null with legacy responses TODO remove comment RECINT
        product: Product | null;
    } = {
        yearCoverage: 50000,
        coveragePeriod: 10, //10, 15, 20, 30
        defaultCoveragePeriod: 20, // 10
        coveragePeriodRange: [],
        illness: [],
        product: null,
    };

    @serializable()
    basicPermanent = {
        lifetimeCoverage: 50000,
        illness: [],
    };

    @serializable()
    bestValue: {
        // total coverage
        yearCoverage: number;
        // IUL coverage
        lifetimeCoverage: number;
        illness: string[];
        // products will be null with legacy responses TODO remove comment RECINT
        termQuote: TermScrapeQuote;
        iulQuote: IulScrapeQuote;
        termProduct: Product;
        iulProduct: Product;
    } = {
        yearCoverage: 50000,
        lifetimeCoverage: 50000,
        illness: [],
        termQuote: null,
        iulQuote: null,
        termProduct: null,
        iulProduct: null,
    };

    @serializable()
    retirementFocused: {lifetimeCoverage: number; illness: string[]; product: Product | null} = {
        lifetimeCoverage: 50000,
        illness: ['chronic'],
        product: null,
    };

    @serializable()
    resultTemporary = {
        perMonth: null,

        lifetimeCoverage: null,
        yearCoverage: null,

        riders: [],
        carriers: [],
    };

    @serializable()
    resultBasic = {
        perMonth: null,

        lifetimeCoverage: null,

        riders: [],
        carriers: [],
    };

    @serializable()
    resultBest: {
        perMonth: number;
        perMonthIUL: number;
        year1_20Coverage: number;
        year20_LifeCoverage: number;
        taxFreeCash: number;
        years: number;
        totalCoverage: number;
        riders: string[];
        carriers: string[];
    } = {
        perMonth: null,
        perMonthIUL: null,
        year1_20Coverage: null,
        year20_LifeCoverage: null,
        totalCoverage: null,
        taxFreeCash: null,
        years: null,
        riders: [],
        carriers: [],
    };

    @serializable()
    resultRetirement = {
        perMonth: null,

        lifetimeCoverage: null,

        taxFreeCash: null,
        years: null,

        riders: [],
        carriers: [],
    };

    @serializable()
    vul = {
        deathBenefit: null,
        contribution: null,
        totalContribution: null,
        vul: null,
        spx: null,
    };

    private temporaryReactionDisposer: IReactionDisposer;
    private basicPermanentReactionDisposer: IReactionDisposer;
    private bestValueReactionDisposer: IReactionDisposer;
    private retirementReactionDisposer: IReactionDisposer;
    private bestValueYearCoverageDisposer: IReactionDisposer;
    private bestValueLifetimeCoverageDisposer: IReactionDisposer;

    constructor(parentStore: UserData) {
        makeAutoObservable(this);
        this.parentStore = parentStore;

        const anyObjFields = (obj) => Object.keys(obj).map((key) => obj[key]);

        this.temporaryReactionDisposer = reaction(
            () => anyObjFields(this.temporary),
            reactionDebounce(() => this.parentStore.parentStore.calcStore.updTemporary(), 100),
        );
        this.basicPermanentReactionDisposer = reaction(
            () => anyObjFields(this.basicPermanent),
            reactionDebounce(() => this.parentStore.parentStore.calcStore.updBasic(), 100),
        );
        this.bestValueReactionDisposer = reaction(
            () => anyObjFields(this.bestValue),
            reactionDebounce(() => this.parentStore.parentStore.calcStore.updBest(), 100),
        );
        this.retirementReactionDisposer = reaction(
            () => anyObjFields(this.retirementFocused),
            reactionDebounce(() => this.parentStore.parentStore.calcStore.updRetirement(), 100),
        );

        this.bestValueYearCoverageDisposer = reaction(
            () => this.bestValue.yearCoverage,
            (cur) => {
                if (this.bestValue.lifetimeCoverage > cur) {
                    runInAction(() => (this.bestValue.yearCoverage = cur + 100000));
                }

                if (this.bestValue.lifetimeCoverage + 100000 > cur) {
                    runInAction(() => (this.bestValue.lifetimeCoverage = cur - 100000));
                }
            },
        );
        this.bestValueLifetimeCoverageDisposer = reaction(
            () => this.bestValue.lifetimeCoverage,
            (cur) => {
                if (this.bestValue.yearCoverage < cur + 100000) {
                    runInAction(() => (this.bestValue.yearCoverage = cur + 100000));
                }
                if (this.bestValue.yearCoverage > 2000000 + cur) {
                    runInAction(() => (this.bestValue.yearCoverage = 2000000 + cur));
                }
            },
        );
    }

    async approveQuoteNew(productType: ProductTypeCode): Promise<void> {
        const userData = this.parentStore;
        const calcStore = this.parentStore.parentStore.calcStore;
        const selectedProductType: ExtendedProductTypeCode = productType;
        const {phase3} = userData;

        let selectedQuote: AnySelectedQuote;

        function mapIllnessToSelectedRiders(illness: string[]): RiderInfo {
            return {
                chronic: !!illness?.find((x) => x === 'chronic'),
                critical: !!illness?.find((x) => x === 'critical'),
            };
        }

        if (selectedProductType === ExtendedProductTypeCode.IUL) {
            selectedQuote = {
                selectedProductType,
                iul: calcStore.iul,
                selectedRiders: mapIllnessToSelectedRiders(phase3.retirementFocused?.illness), // TODO
            };
        } else if (selectedProductType === ExtendedProductTypeCode.Term) {
            selectedQuote = {
                selectedProductType,
                term: calcStore.term,
                selectedRiders: mapIllnessToSelectedRiders(phase3.temporary?.illness),
            };
        } else if (selectedProductType === ExtendedProductTypeCode.Combo) {
            selectedQuote = {
                selectedProductType,
                combo: calcStore.combo,
                selectedRiders: mapIllnessToSelectedRiders(phase3.bestValue?.illness),
            };
        } else if (selectedProductType === ExtendedProductTypeCode.VUL) {
            selectedQuote = {
                selectedProductType,
                vul: this.vul,
            };
        }

        const approvalData: ApprovalData = {
            consent: !!userData.consent,
            selectedQuote,
        };

        const approvalRequest: ApprovalRequest = {
            ...mapStateToEligibleProductsRequestData(userData),
            approvalData,
        };

        const displayProductType = productTypeToDisplayMap.get(selectedProductType);

        this.parentStore.updateField('selectedProductTypeMx', displayProductType);

        const logData = {
            selectedData: displayProductType,
            email: userData.email,
            selectedProductTypeMx: this.parentStore.selectedProductTypeMx,
            recommendedProductTypeMx: this.parentStore.recommendedProductTypeMx,
            getPreApprovedClicked: new Date(),
            ...toJS(phase3[productTypeToTypeData.get(selectedProductType)]),
        };

        this.parentStore.parentStore.logStore.logAction({
            action: 'selected-quote',
            data: logData,
        });

        const customerId = (await Phase3Api.sendApproveQuoteNew(approvalRequest))?.customerId;
        if (customerId) this.parentStore.updateField('customerId', customerId);
    }

    updateField = updateField.bind(this);

    updTemp = updateCustomField('temporary').bind(this);
    updBasic = updateCustomField('basicPermanent').bind(this);
    updBest = updateCustomField('bestValue').bind(this);
    updRetir = updateCustomField('retirementFocused').bind(this);

    updResultTemporary = updateCustomField('resultTemporary').bind(this);
    updResultBasic = updateCustomField('resultBasic').bind(this);
    updResultBest = updateCustomField('resultBest').bind(this);
    updResultRetirement = updateCustomField('resultRetirement').bind(this);

    dispose() {
        this.temporaryReactionDisposer();
        this.basicPermanentReactionDisposer();
        this.bestValueReactionDisposer();
        this.retirementReactionDisposer();
        this.bestValueYearCoverageDisposer();
        this.bestValueLifetimeCoverageDisposer();
    }
}

export default Phase3;
