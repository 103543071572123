import {createTheme} from '@mui/material';

export const palette = {
    white: '#fff',
    gray: {
        100: '#F4F5F7',
        200: '#F8F8F8',
        300: '#D6DFDE',
        400: '#8e8e8e',
        500: '#5d5d5d',
    },
    primary: {
        100: '#edfcf2',
        200: '#A8EDBF',
        300: '#9cedb7',
        400: '#5ae288',
        500: '#4fc878',
        600: '#12933E',
    },
    secondary: {
        100: '#e8edec',
        200: '#bac9c7',
        300: '#5D847F',
        400: '#3a645f',
        500: '#174943',
    },
    tertiary: {
        400: '#fcfbf3',
        500: '#f4f0d7',
        600: '#272b4d',
        800: '#745E05',
    },
};

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    typography: {
        h1: {
            fontDisplay: 'swap',
            fontSize: '72px',
            fontFamily: 'LoraRegular',
            fontWeight: 400,
            color: palette.secondary[500],
        },
        h2: {
            fontDisplay: 'swap',
            fontSize: '54px',
            fontFamily: 'LoraRegular',
            fontWeight: 400,
            color: palette.secondary[500],
        },
        h3: {
            fontDisplay: 'swap',
            fontSize: '48px',
            fontFamily: 'LoraRegular',
            fontWeight: 400,
            color: palette.secondary[500],
            '@media (max-width: 600px)': {
                fontSize: '28px',
            },
        },
        h4: {
            fontDisplay: 'swap',
            fontSize: '24px',
            fontFamily: 'LoraRegular',
            fontWeight: 400,
            color: palette.secondary[500],
        },
        button: {
            fontSize: '28px',
            fontFamily: 'LoraRegular',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '120%',
            cursor: 'pointer',
            textTransform: 'none',
            color: palette.tertiary[600],
            '@media (max-width: 900px)': {
                fontSize: '24px',
            },
        },
        subtitle1: {
            fontDisplay: 'swap',
            fontFamily: 'FredokaLight',
            fontWeight: 300,
            fontSize: '26px',
            lineHeight: '150%',
            color: palette.secondary[500],
            '@media (max-width: 600px)': {
                fontSize: '18px',
            },
        },
        subtitle2: {
            fontDisplay: 'swap',
            fontFamily: 'FredokaRegular',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '150%',
            color: palette.secondary[500],
        },
        body1: {
            fontDisplay: 'swap',
            fontFamily: 'FredokaRegular',
            fontSize: '1rem',
            color: '#5C5C5C',
        },
        body2: {
            fontDisplay: 'swap',
            fontFamily: 'FredokaLight',
            fontWeight: 300,
            fontSize: '18px',
            lineHeight: '160%',
            color: palette.secondary[500],
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: palette.primary[400],
            light: palette.primary[200],
            dark: palette.primary[600],
            contrastText: palette.primary[300],
        },
        secondary: {
            main: palette.secondary[500],
            light: palette.secondary[100],
            dark: palette.secondary[400],
            contrastText: palette.secondary[200],
        },
        warning: {
            main: palette.primary[500],
            light: palette.secondary[300],
            dark: palette.gray[500],
            contrastText: palette.tertiary[400],
        },
        info: {
            main: palette.gray[200],
            light: palette.gray[300],
            dark: palette.gray[400],
            contrastText: palette.white,
        },
        emerald: {
            main: palette.primary[400],
            light: palette.primary[200],
            dark: palette.primary[600],
            contrastText: palette.primary[300],
        },
        white: {
            main: palette.primary[400],
            light: palette.primary[200],
            dark: palette.primary[600],
            contrastText: palette.primary[300],
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    '&:focus': {
                        outline: 'none',
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    height: 'auto !important',
                    lineHeight: '160%',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    padding: '16px 14px',
                    fontSize: '18px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#5ae288',
                    },
                },
                notchedOutline: {
                    borderWidth: '1px',
                    borderColor: '#174943',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
                        borderWidth: '1px',
                    },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: '10em',
                    fontFamily: 'FredokaRegular',
                },
                outlined: {
                    borderWidth: '2px',
                    '&:hover': {
                        borderWidth: '2px',
                    },
                },
                outlinedSecondary: {
                    color: palette.secondary[500],
                    borderColor: palette.secondary[500],
                    '&:hover': {
                        color: palette.white,
                        backgroundColor: palette.secondary[500],
                    },
                },
                containedPrimary: {
                    color: palette.secondary[500],
                    '&:hover': {
                        color: palette.secondary[500],
                        backgroundColor: palette.primary[300],
                    },
                },
                containedSecondary: {
                    color: palette.white,
                    '&:hover': {
                        color: palette.white,
                        backgroundColor: palette.secondary[400],
                    },
                },
                sizeMedium: {
                    lineHeight: '150%',
                    padding: '22px 37px',
                    height: '27px',
                    letterSpacing: '0.1em',
                    textTransform: 'uppercase',
                    fontSize: '18px',
                },
            },
        },
    },
});
