import {ExtendedProductTypeCode} from '@amplify/athena-models';
import {ActiveProductType} from '../../store/CalculatorStore';

export enum TabsList {
    Temporary = 'Term',
    BasicPermanent = 'Basic Permanent',
    BestValue = 'Combination',
    RetirementFocused = 'IUL Policy',
    VUL = 'VUL Policy',
}

export const TabsListArr = [
    TabsList.Temporary,
    TabsList.BasicPermanent,
    TabsList.BestValue,
    TabsList.RetirementFocused,
    TabsList.VUL,
];

export function getProductTypeNumberFromTabList(selectedTab: TabsList): number {
    return TabsListArr.findIndex((tab) => tab === selectedTab) + 1;
}

// TODO we should just get rid of this TabsList enum and reuse ExtendedProductTypeCode
export const productTypeToTabsListMap = new Map([
    [ExtendedProductTypeCode.Term, TabsList.Temporary],
    [ExtendedProductTypeCode.Basic, TabsList.BasicPermanent],
    [ExtendedProductTypeCode.Combo, TabsList.BestValue],
    [ExtendedProductTypeCode.VUL, TabsList.VUL],
    [ExtendedProductTypeCode.IUL, TabsList.RetirementFocused],
]);

export const tabsListToProductTypeMap = new Map<TabsList, ExtendedProductTypeCode>(
    Array.from(productTypeToTabsListMap.entries()).map(([k, v]) => [v, k]),
);

export const activeProductToProductTypeCodeMap = new Map<ActiveProductType, ExtendedProductTypeCode>([
    ['combo', ExtendedProductTypeCode.Combo],
    ['iul', ExtendedProductTypeCode.IUL],
    ['vul', ExtendedProductTypeCode.VUL],
    ['term', ExtendedProductTypeCode.Term],
]);
