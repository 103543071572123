import {LeadsInstance} from '../instancesLogApi';

export default class LeadsApi {
    static log = async (
        userId: string,
        source: string,
        origin: string,
        event: string,
        partner_user_id: string,
    ): Promise<undefined> => {
        const response = await LeadsInstance.post(`/${source}/${userId}/${event}`, {
            userId,
            action: event,
            data: {utm_origin: origin, utm_source: source, partner_user_id: partner_user_id},
        });

        return response.data;
    };
}
