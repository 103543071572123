/* eslint @typescript-eslint/no-explicit-any: 0 */

export enum ResponseStatus {
    success = 'success',
    info = 'info',
    warning = 'warning',
    error = 'error',
}

export type UnifiedClientResponse = {
    status: ResponseStatus;
    message?: string;
    payload?: ServerData;
    errorCode?: number;
};

export type ServerData = object;

export type ServerResponse = {
    data: ServerData;
    statusCode: number;
};
