import {SessionInstance} from '../instancesLogApi';
import UserData from '../../store/UserData';
import {MixpanelLogEvent} from '@amplify/athena-models';
import {v4} from 'uuid';

export default class LoggingApi {
    static getSessionId = async (): Promise<string> => {
        const response = await SessionInstance.post('');

        return response?.data?.session;
    };

    static log = async (
        userId: string,
        data: {action: string; data: UserData},
        {useQueue = true} = {},
    ): Promise<undefined> => {
        const mixpanelData: MixpanelLogEvent = {
            ...data,
            userId,
            distinct_id: userId,
            $insert_id: v4(),
            time: Date.now(),
            sendingApp: 'WALKTHROUGH',
        };

        const loggerUrl = useQueue ? `/${data.action}/sqs` : `/${data.action}`;

        const response = await SessionInstance.post(loggerUrl, mixpanelData);

        return response.data;
    };
}
