import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const MONTH_DATE_FORMAT = 'MM/YYYY';
const DAY_DATE_FORMAT = 'DD/MM/YYYY';

const parseBool = (val: string | boolean): boolean => {
    if (val === 'true' || val === true) return true;
    if (val === 'false' || val === false) return false;
    return null;
};

const parseDate = (val: string, format?: string, outputFormat?: string): string =>
    dayjs(val, format || MONTH_DATE_FORMAT).format(outputFormat || '');

const formatToUSD = (value: number, minimumFractionDigits = 2, maximumFractionDigits = 2): string => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits,
        maximumFractionDigits,
    });

    return formatter.format(value);
};

const parseHeightStringToInches = (heightString: string): number => {
    const heightRegexOp = /^(\d{1,2})[\']?((\d)|([0-1][0-2]))?[\"]?$/g.exec(heightString);
    const feet = parseInt(heightRegexOp[1]);
    const inch = parseInt(heightRegexOp[2]);
    return feet * 12 + inch;
};

export {parseHeightStringToInches, formatToUSD, parseBool, parseDate, DAY_DATE_FORMAT, MONTH_DATE_FORMAT};
