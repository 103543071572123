import {makeAutoObservable, runInAction} from 'mobx';
import {anyCond, diagCond, noneValue} from './types';
import updateField from '../../helpers/updateField';
import {CholesterolRange, FrequencyOfUse} from '@amplify/athena-models';
import {serializable} from '../sync/serialization';

class Phase2 {
    @serializable()
    height = '';

    @serializable()
    weight = '';

    @serializable()
    diagCond = [
        {label: diagCond.None, value: false},
        {label: diagCond.Asthma, value: false},
        {label: diagCond.Cancer, value: false},
        {label: diagCond.Diabetes, value: false},
        {label: diagCond.HeartAttack, value: false},
        {label: diagCond.BloodPressure, value: false},
        {label: diagCond.HighCholesterol, value: false},
        {label: diagCond.PsychDisorder, value: false},
        {label: diagCond.SleepApnea, value: false},
        {label: diagCond.Stroke, value: false},
        {label: diagCond.OtherCondition, value: false},
    ];

    @serializable()
    behaviors = [
        {label: anyCond.None, value: false},
        {label: anyCond.AlcoholOrDrug, value: false},
        {label: anyCond.DUI, value: false},
        {label: anyCond.SuspendedLicense, value: false},
        {label: anyCond.MovingViolations, value: false},
        {label: anyCond.CriminalOffense, value: false},
        {label: anyCond.Bankruptcy, value: false},
    ];

    @serializable()
    treatment = null;

    @serializable()
    treatmentDetails = '';

    @serializable()
    treatmentWhen = '';

    @serializable()
    treatmentClinic = '';

    @serializable()
    alcoDrugFreeYears = '';

    @serializable()
    alcoBeverages = null;

    @serializable()
    alcoType = [];

    @serializable()
    alcoholicDrinksWeekly = '';

    @serializable()
    usedTobacco = false;

    @serializable()
    usedTobaccoProduct = '';

    @serializable()
    usedTobaccoFrequency = '';

    @serializable()
    usedTobaccoLastDate = '';

    @serializable()
    usedMarijuana = false;

    @serializable()
    usedMarijuanaProduct = '';

    @serializable()
    usedMarijuanaFrequency: FrequencyOfUse | null = null;

    @serializable()
    usedMarijuanaLastDate = '';

    @serializable()
    otherSmokeProductUsed = false;

    @serializable()
    otherSmokeProductSubstance = '';

    @serializable()
    otherSmokeProductProduct = '';

    @serializable()
    otherSmokeProductFrequency = '';

    @serializable()
    otherSmokeProductLastDate = '';

    @serializable()
    diagProductsUsed = [];

    @serializable()
    diagWhenUsed = '';

    @serializable()
    diagHowFreqUsed = '';

    @serializable()
    diagAsthmaLastHosp = '';

    @serializable()
    diagCancerBasalCell = '';

    @serializable()
    diagCancerWhen = '';

    @serializable()
    diagPsychCondition = [];

    @serializable()
    diagPsychLastHosp = '';

    @serializable()
    diagPsychMedication = '';

    @serializable()
    diagDiabetesType = true;

    @serializable()
    diagDiabetesTreatments = '';

    @serializable()
    diagDiabetesA1C = '';

    @serializable()
    diagHeartAttackWhen = '';

    @serializable()
    diagHighCholHDL: CholesterolRange | null = null;

    @serializable()
    diagBloodPressDiagnosisDate = '';

    @serializable()
    diagBloodPressLow = '';

    @serializable()
    diagBloodPressHigh = '';

    @serializable()
    diagBloodPressMedication = '';

    @serializable()
    diagSleepLevel = '';

    @serializable()
    diagSleepCPAP = '';

    @serializable()
    diagStrokeWhen = '';

    @serializable()
    healthFamilyMedical = [];

    @serializable()
    otherConditionDescribe = '';

    @serializable()
    suspendedLicenseStill = null;

    @serializable()
    suspendedLicenseWhen = '';

    @serializable()
    DUIWhen = '';

    @serializable()
    movingViolationsHowMany = '';

    @serializable()
    criminalOffenseDetails = '';

    @serializable()
    criminalOffenseWhen = '';

    @serializable()
    paroleComplete = 'lessthan5';

    @serializable()
    bankruptcyYear = '';

    @serializable()
    bankruptcyChapter = '';

    @serializable()
    bankruptcyIsOpen = null;

    @serializable()
    bankruptcyDischargedWhen = '';

    @serializable()
    aids = null;

    constructor() {
        makeAutoObservable(this);
    }

    updateField = updateField.bind(this);

    updateCondition(conditionName: string, val: boolean, conditionList: {label: string; value: boolean}[]): void {
        const noneLabel = noneValue.Label;
        const index = conditionList.findIndex((condition) => condition.label === conditionName);
        const noneValueIndex = conditionList.findIndex((condition) => condition.label === noneLabel);
        if (index >= 0) {
            runInAction(() => {
                if (conditionName === conditionList[noneValueIndex]?.label && val === true) {
                    conditionList.forEach((condition) => (condition.value = false));
                } else {
                    conditionList[noneValueIndex].value = false;
                }
                conditionList[index].value = val;
            });
        }
    }

    updateDiagCondition(conditionName: string, val: boolean): void {
        this.updateCondition(conditionName, val, this.diagCond);
    }

    updateAnyCondition(conditionName: string, val: boolean): void {
        this.updateCondition(conditionName, val, this.behaviors);
    }
}

export default Phase2;
