import React from 'react';
import {Stack} from '@mui/material';
import Loader from '../Loader/Loader';

export function LoadingState() {
    return (
        <Stack alignItems="center" justifyContent="center" height="100%">
            <Loader />
        </Stack>
    );
}
