import axios from 'axios';
import {LOG_API_URL} from '../config';

export const SessionInstance = axios.create({
    baseURL: `${LOG_API_URL}/v2/log/walkthrough`,
});

export const LeadsInstance = axios.create({
    baseURL: `${LOG_API_URL}/leads`,
});

export const VULInstance = axios.create({
    baseURL: `${LOG_API_URL}/vul`,
});
