import {makeAutoObservable} from 'mobx';
import ProgressStore from './ProgressStore';
import ModalStore from './ModalStore';
import ComponentsStore from './ComponentsStore';
import {type RootStore} from '../Store';
import {serializable} from '../sync/serialization';

export class UIStore {
    parentStore: RootStore;

    @serializable()
    progressStore: ProgressStore;

    @serializable()
    modalStore: ModalStore;

    @serializable()
    componentsStore: ComponentsStore;

    constructor(parentStore: RootStore) {
        makeAutoObservable(this);
        this.parentStore = parentStore;
        this.progressStore = new ProgressStore(this);
        this.modalStore = new ModalStore();
        this.componentsStore = new ComponentsStore();
    }

    reset() {
        this.progressStore = new ProgressStore(this);
        this.modalStore = new ModalStore();
        this.componentsStore = new ComponentsStore();
    }
}

export default UIStore;
