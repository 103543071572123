import {getAbFlags} from '@amplify/abflag';
import {AppAbFlag} from '@amplify/athena-models';
import {enableStaticRendering} from 'mobx-react';
import {AppProps} from 'next/app';
import React, {PropsWithChildren, useContext, useEffect, useState, createContext, useRef, ComponentType} from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import {RootStore} from '../store/Store';
import {LoadingState} from './LoadingState/LoadingState';

const isServer = typeof window === 'undefined';
enableStaticRendering(isServer);

const BACKUP_AB_FLAG_RESULT = [
    {
        flagName: 'emptyAbflagResult',
        probabilityTrue: 1,
    },
];

export const StoreContext = createContext<RootStore>(null);

export function useStore() {
    return useContext(StoreContext);
}

export type StoreProviderProps = {
    abFlagScheme: AppAbFlag[];
};

export function StoreProvider({abFlagScheme, children}: PropsWithChildren<StoreProviderProps>) {
    const [store, setStore] = useState(null);
    const isInitialized = useRef(false);

    useEffect(() => {
        if (!isInitialized.current && abFlagScheme) {
            const init = async () => {
                const params = new URLSearchParams(window.location.search);
                console.log('abFlagScheme ' + JSON.stringify(abFlagScheme));
                const abFlags = getAbFlags(abFlagScheme || []);
                const store = await RootStore.init({
                    experiment: abFlags,
                    hubId: params.get('hubId'),
                    userId: params.get('userId'),
                });

                isInitialized.current = true;
                setStore(store);
            };
            init();
        }
    }, [abFlagScheme]);

    if (!store) return <LoadingState />;

    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
}

export type PageProps = {
    abFlagScheme: AppAbFlag[];
};

export function withStore<T extends AppProps<PageProps>>(Component: ComponentType<T>) {
    function ComponentWithStore(props: T) {
        let abFlagScheme = props.pageProps.abFlagScheme;

        if (!abFlagScheme || !abFlagScheme.length) {
            console.error(
                'abFlagScheme not found! Please export getStaticProps on each page. Falling bak to backup abFlagScheme',
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            abFlagScheme = BACKUP_AB_FLAG_RESULT as any; // FIXME: any here until models updated
        }

        return (
            <StoreProvider abFlagScheme={abFlagScheme}>
                <Component {...props} />
            </StoreProvider>
        );
    }

    hoistNonReactStatics(ComponentWithStore, Component);

    return ComponentWithStore;
}
