export const prodMode = process.env.NEXT_PUBLIC_ENV === 'production';
export const stagingMode = process.env.NEXT_PUBLIC_ENV === 'staging';
export const devMode = process.env.NEXT_PUBLIC_ENV === 'dev';
console.log('started in mode:', process.env.NEXT_PUBLIC_ENV || 'local');

export const BASE_URL = prodMode
    ? 'https://backend.beamplify.com/backend'
    : stagingMode
    ? 'https://quotes-staging-backend.beamplify.com/backend'
    : 'https://zip.amplifylife.io/backend';

export const BASE_URL_ATHENA = prodMode
    ? 'https://api.beamplify.com'
    : stagingMode
    ? 'https://api-dev.beamplify.com'
    : 'https://api.amplifylife.io';

export const API_KEY = prodMode ? '357192d2-e8d7-4f87-9def-5bb17ada862b' : '6722651b-36cd-47b6-a6e7-dfaf88110e6f';

export const CUSTOMER_PORTAL_URL = prodMode
    ? process.env.NEXT_PUBLIC_CUSTOMER_PORTAL_URL || 'https://portal.getamplifylife.com'
    : stagingMode
    ? 'https://portal-dev.beamplify.com'
    : 'https://portal.amplifylife.io';

// export const LOG_API_URL = 'https://api.beamplify.com'
export const LOG_API_URL = BASE_URL_ATHENA;

export const tagManagerId = 'GTM-5J8Q22W';

export const analyticsId = 'UA-151167004-1';

export const hotjarId = 1847658;
export const hotjarSv = 6;

export const facebookPixelId = '526430414837727';

export const storeVersion = '0.0.18';

export const growthBookClientKey = prodMode
    ? 'sdk-FH4rwVMYWDClvGvk' // production
    : stagingMode
    ? 'sdk-UqQwXRg4bXMJc3v' // staging
    : 'sdk-KMV2RUIUHTnlQPhH'; // dev
