import React from 'react';
import {makeAutoObservable, reaction, runInAction} from 'mobx';
import {serializable} from '../sync/serialization';

class ModalStore {
    @serializable()
    modalIsOpen = false;

    @serializable()
    modalEmailIsOpen = false;

    @serializable()
    modalToolTip: {header?: string | React.ReactNode; text?: string | React.ReactNode} = null;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => [this.modalEmailIsOpen, this.modalToolTip],
            (arg) => runInAction(() => (this.modalIsOpen = arg.some((v) => !!v))),
        );
    }

    openModalEmail(): void {
        this.modalEmailIsOpen = true;
    }
    closeModalEmail(): void {
        this.modalEmailIsOpen = false;
    }

    openModalToolTip(modal: {header?: string; text?: string | React.ReactNode}): void {
        this.modalToolTip = modal;
    }
    closeModalToolTip(): void {
        this.modalToolTip = null;
    }
}

export default ModalStore;
