import '../styles/globals.scss';
import React, {useEffect} from 'react';
import {CookieStorage} from 'cookie-storage';
import Head from 'next/head';
import Router from 'next/router';
import TagManager from 'react-gtm-module';
import {hotjar} from 'react-hotjar';
import {ThemeProvider} from '@mui/material';
import {utmInjector} from '../api/injector';
import {hotjarId, hotjarSv, prodMode, tagManagerId} from '../config';
import {theme} from '../helpers/theme';
import RouterHoc from '../layouts/RouterHoc/RouterHoc';
import {checkOpenedChat} from '../helpers/checkOpenedChat';
import applyOverscrollClass from '../helpers/mobileFbBrowserAdjustment';
import {useDetectAdBlock} from 'adblock-detect-react';
import {PageProps, useStore, withStore} from '../components/StoreProvider';
import {AppProps} from 'next/app';
import {observer} from 'mobx-react';
import {serialize} from '../store/sync/serialization';
import {actions, categories, pushEvent} from '../helpers/analyticsEvents';

declare global {
    interface Window {
        dataLayer: unknown[];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        opera: any;
        ga: (action: string, arg1: string, arg2: string) => void;
        _hsq: {push: (args: Array<string>) => void};
        HubSpotConversations: {
            widget: {
                load: () => void;
                status: () => {loaded: boolean};
            };
        };
        hsConversationsOnReady: Array<() => void>;
    }
}

function useAdBlockDetector() {
    const store = useStore();
    const adBlockDetected = useDetectAdBlock();

    useEffect(() => {
        if (adBlockDetected) {
            store.logStore.logAction({
                action: 'detected-adblock',
                data: serialize(store.userData),
            });
        }
    }, [adBlockDetected, store]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function App(props: AppProps<PageProps>) {
    const mobxStore = useStore();
    const cookieStorage = new CookieStorage();
    useAdBlockDetector();

    useEffect(() => {
        checkOpenedChat(cookieStorage);
        utmInjector(mobxStore);
        const queryParams = new URLSearchParams(window.location.search);
        const prefillDate = queryParams.get('prefill[dateOfBirth]');
        if (prefillDate !== null) {
            const date = new Date(prefillDate);
            mobxStore.userData.phase1.updateField('dateOfBirth')(
                `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
                    .getDate()
                    .toString()
                    .padStart(2, '0')}/${date.getFullYear()}`,
            );
            const prefillGender = queryParams.get('prefill[gender]');
            if (prefillGender !== null)
                mobxStore.userData.phase1.updateField('gender')(prefillGender as 'male' | 'female' | '');
            const prefillZip = queryParams.get('prefill[zip]');
            if (prefillZip !== null) mobxStore.userData.phase1.updateField('zipCode')(prefillZip);
            const prefillMaritalStatus = queryParams.get('prefill[maritialStatus]');
            if (prefillMaritalStatus !== null)
                mobxStore.userData.phase1.updateField('maritalStatus')(
                    (prefillMaritalStatus?.charAt(0)?.toUpperCase() + prefillMaritalStatus?.slice(1)) as
                        | 'Single'
                        | 'Married'
                        | 'Divorced'
                        | 'Separated'
                        | 'Widowed'
                        | '',
                );
            const prefillNKids = queryParams.get('prefill[numberOfKids]');
            if (prefillNKids !== null) mobxStore.userData.phase1.updateField('numberOfChildren')(prefillNKids);
            const prefillHouseholdIncome = queryParams.get('prefill[annualHouseholdIncomeNumber]');
            if (prefillHouseholdIncome) {
                mobxStore.userData.phase1.updateField('annualHouseholdIncomeText')(prefillHouseholdIncome);
                mobxStore.userData.phase1.updateField('annualHouseholdIncomeNumeric')(Number(prefillHouseholdIncome));
                mobxStore.userData.phase1.updateField('annualIndividualIncome')(prefillHouseholdIncome);
            }
            const prefillTotalDebt = queryParams.get('prefill[totalDebt]');
            if (prefillTotalDebt !== null)
                mobxStore.userData.phase1.updateField('estimatedDebtTotal')(prefillTotalDebt);
            const prefillTotalRetirementSavings = queryParams.get('prefill[totalRetirementSavings]');
            if (prefillTotalRetirementSavings !== null)
                mobxStore.userData.phase1.updateField('estimatedSavingTotal')(Number(prefillTotalRetirementSavings));
            const prefillInvestmentRisk = queryParams.get('prefill[preferredInvestmentRisk]');
            if (prefillInvestmentRisk !== null)
                mobxStore.userData.phase1.updateField('investmentRisk')(
                    prefillInvestmentRisk as 'high' | 'moderate' | 'low' | '',
                );
            const prefillCalcKeys = [
                'dateOfBirth',
                'gender',
                'zip',
                'maritialStatus',
                'numberOfKids',
                'annualHouseholdIncomeNumber',
                'totalDebt',
                'totalRetirementSavings',
                'preferredInvestmentRisk',
                'stateId',
                'totalLiquidAssets',
                'yearUntilMoneyNeeded',
                'preferredMonthlySpend',
                'recomendedMonthlySpend',
                'existingLifeInsurance',
            ];
            for (let i = 0; i < prefillCalcKeys.length; i++) queryParams.delete(`prefill[${prefillCalcKeys[i]}]`);
            window.location.search = queryParams.toString();
        }
        mobxStore.logStore.logLead('landed');
        mobxStore.logStore.updateBrowserInfo();
        mobxStore.userData.initAutoDefaultPages();

        if (prodMode) {
            TagManager.initialize({gtmId: tagManagerId});
            hotjar.initialize(hotjarId, hotjarSv);
        }

        if (process.env.NEXT_PUBLIC_DISABLE_FACEBOOK_PIXEL === 'true') {
            // we load facebook pixel from gtm instead in order to make it easier
            // for us to disable route change triggers as needed
            // in order to prevent duplicate events being sent back to facebook
            Router.events.on('routeChangeComplete', () => {
                pushEvent(categories.routeChangeComplete, actions.routeChange);
            });
        } else {
            import('react-facebook-pixel')
                .then((x) => x.default)
                .then((ReactPixel) => {
                    ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID || '526430414837727');
                    ReactPixel.pageView();

                    Router.events.on('routeChangeComplete', () => {
                        ReactPixel.pageView();
                    });
                });
        }
    }, []);

    useEffect(() => {
        const {os, browser} = mobxStore.logStore || {};
        const shouldDisableOverscroll = os === 'iOS' && browser === 'Facebook Mobile';
        applyOverscrollClass(shouldDisableOverscroll);

        return () => {
            applyOverscrollClass(false);
        };
    }, []);

    const {Component, pageProps} = props;

    return (
        <>
            <Head>
                <title>Amplify Quotes</title>
            </Head>
            <ThemeProvider theme={theme}>
                <RouterHoc>
                    <Component {...pageProps} />
                </RouterHoc>
            </ThemeProvider>
        </>
    );
}

export default withStore(observer(App));
