import {AxiosInstance, Method} from 'axios';
import ApiErrors from './apiErrorCodes';
import {ResponseStatus, ServerResponse, UnifiedClientResponse} from './types';
import * as Sentry from '@sentry/nextjs';

const logError = (data) => {
    Sentry.captureMessage(JSON.stringify(data));
};

class CustomError extends Error {
    public response: any;

    constructor(message: string) {
        super(message);
        this.name = 'CustomError';
    }
}

const requestHelper = async (
    data: any | null,
    url: string,
    instance: AxiosInstance,
    method: Method,
    uploadProgressCallback?: (progress: number) => void,
    params?: Record<string, unknown>,
): Promise<UnifiedClientResponse> => {
    const onUploadProgress = (progressEvent: {loaded: number; total: number}) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        uploadProgressCallback && uploadProgressCallback(percentCompleted);
    };

    const config = data ? {method, url, data, onUploadProgress} : {method, url, params};

    try {
        const response = await instance.request<ServerResponse>(config);

        if (response.status === 200 || response.status === 201) {
            return {
                status: ResponseStatus.success,
                payload: response.data.data,
            };
        }

        const error = new CustomError('Something went wrong, please try again later');
        error.response = response;
        throw error;
    } catch (e) {
        console.log('url', url);
        console.log('data', JSON.stringify(data));
        console.log('--raw catch err', JSON.stringify(e?.response?.data));

        logError(data);

        return {
            status: ResponseStatus.error,
            message: ApiErrors[e.response?.data?.statusCode] ?? 'Something went wrong, please try again later',
            errorCode: e.response?.data?.statusCode,
        };
    }
};

export default requestHelper;
