import {IReactionPublic} from 'mobx';

const reactionDebounce = function debounceEffect<T>(
    effect: (arg: T, prev: T, r: IReactionPublic) => void,
    debounceMs: number,
) {
    let timer: NodeJS.Timeout;
    return (arg: T, prev: T, r: IReactionPublic) => {
        clearTimeout(timer);
        timer = setTimeout(() => effect(arg, prev, r), debounceMs);
    };
};

export default reactionDebounce;
