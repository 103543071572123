import dayjs, {Dayjs} from 'dayjs';

export const overallHealthConditions = {
    excellent: 'excellent',
    average: 'average',
    notGreat: 'not great',
};

export const occupation = [
    {value: 1, label: 'Agriculture and Natural Resources'},
    {value: 2, label: 'Architecture and Construction'},
    {value: 3, label: 'Arts'},
    {value: 4, label: 'Management and Administration'},
    {value: 5, label: 'Education'},
    {value: 6, label: 'Finance'},
    {value: 7, label: 'Government and Publice Service'},
    {value: 8, label: 'Healthcare'},
    {value: 9, label: 'Hospitality'},
    {value: 10, label: 'Human Services'},
    {value: 11, label: 'Law'},
    {value: 12, label: 'Manufacturing'},
    {value: 13, label: 'Sales and Marketing'},
    {value: 14, label: 'Technology and Science'},
    {value: 15, label: 'Transportation and Logistics'},
    {value: 16, label: 'Unemployed'},
    {value: 17, label: 'Others'},
];

export const annualHouseholdIncome = [
    {value: 1, label: '$0 – 29,999', min: 0},
    {value: 2, label: '$30,000 – 49,999', min: 30000},
    {value: 3, label: '$50,000 – 99,999', min: 50000},
    {value: 4, label: '$100,000 – 249,999', min: 100000},
    {value: 5, label: '$250,000 +', min: 250000},
];

export const maritalStatus = [
    {value: 'single', label: 'Single'},
    {value: 'married', label: 'Married'},
    {value: 'divorced', label: 'Divorced'},
    {value: 'separated', label: 'Separated'},
    {value: 'widowed', label: 'Widowed'},
];

export const times = [
    {label: '10 - 11AM', value: '10 - 11AM'},
    {label: '11AM - 12PM', value: '11AM - 12PM'},
    {label: '12 - 1PM', value: '12 - 1PM'},
    {label: '1 - 2PM', value: '1 - 2PM'},
    {label: '2 - 3PM', value: '2 - 3PM'},
    {label: '3 - 4PM', value: '3 - 4PM'},
    {label: '4 - 5PM', value: '4 - 5PM'},
    {label: '5 - 6PM', value: '5 - 6PM'},
    {label: '6 - 7PM', value: '6 - 7PM'},
    {label: '7 - 8PM', value: '7 - 8PM'},
    {label: '8 - 9PM', value: '8 - 9PM'},

    // { label: '9AM - 12PM', value: '1' },
    // { label: '12 - 3PM', value: '2' },
    // { label: '3 - 6PM', value: '3' },
    // { label: '6 - 9PM', value: '4' },
];

export const relationships = [
    {value: 'Child', label: 'Child'},
    {value: 'Spouse', label: 'Spouse'},
    {value: 'Parent', label: 'Parent'},
    {value: 'Grandparent', label: 'Grandparent'},
    {value: 'Other', label: 'Other'},
];

export const dates = Array.from({length: 5}).map((_, i) => {
    const nextDay = (i: number): Dayjs => dayjs().minute(0).hour(0).second(0).add(i, 'day');
    const label = nextDay(i + 1).format('ddd, MMM D');
    const value = nextDay(i + 1).format('');
    return {label, value};
});

export const insurances = [
    {value: 'Accidental', label: 'Accidental'},
    {value: 'Guarantee Issue', label: 'Guarantee Issue'},
    {value: 'Guarantee Universal Life', label: 'Guarantee Universal Life'},
    {value: 'Indexed Universal Life', label: 'Indexed Universal Life'},
    {value: 'Variable Universal Life', label: 'Variable Universal Life'},
    {value: 'Term', label: 'Term'},
    {value: 'Whole Life', label: 'Whole Life'},
    {value: 'Unknown', label: 'Unknown'},
];

export const heights = [{value: `4'7"`, label: `< 4'8"`}].concat(
    Array.from({length: 40}, (_, i) => {
        const offset1 = 4;
        const offset2 = 8;
        const inOne = 12; //minus 1
        const label = `${+`${(i + offset2) / inOne}`[0] + offset1}'${(i + offset2) % inOne}"`;
        return {value: label, label};
    }),
);
export const countries = [
    {
        value: 'united states',
        label: 'United States of America',
    },
    {
        value: 'afghanistan',
        label: 'Afghanistan',
    },
    {
        value: 'albania',
        label: 'Albania',
    },
    {
        value: 'algeria',
        label: 'Algeria',
    },
    {
        value: 'american samoa',
        label: 'American Samoa',
    },
    {
        value: 'andorra',
        label: 'AndorrA',
    },
    {
        value: 'angola',
        label: 'Angola',
    },
    {
        value: 'anguilla',
        label: 'Anguilla',
    },
    {
        value: 'antigua and barbuda',
        label: 'Antigua and Barbuda',
    },
    {
        value: 'argentina',
        label: 'Argentina',
    },
    {
        value: 'armenia',
        label: 'Armenia',
    },
    {
        value: 'aruba',
        label: 'Aruba',
    },
    {
        value: 'australia',
        label: 'Australia',
    },
    {
        value: 'austria',
        label: 'Austria',
    },
    {
        value: 'azerbaijan',
        label: 'Azerbaijan',
    },
    {
        value: 'bahamas',
        label: 'Bahamas',
    },
    {
        value: 'bahrain',
        label: 'Bahrain',
    },
    {
        value: 'bangladesh',
        label: 'Bangladesh',
    },
    {
        value: 'barbados',
        label: 'Barbados',
    },
    {
        value: 'belarus',
        label: 'Belarus',
    },
    {
        value: 'belgium',
        label: 'Belgium',
    },
    {
        value: 'belize',
        label: 'Belize',
    },
    {
        value: 'benin',
        label: 'Benin',
    },
    {
        value: 'bermuda',
        label: 'Bermuda',
    },
    {
        value: 'bhutan',
        label: 'Bhutan',
    },
    {
        value: 'bolivia',
        label: 'Bolivia',
    },
    {
        value: 'bosnia and herzegovina',
        label: 'Bosnia and Herzegovina',
    },
    {
        value: 'botswana',
        label: 'Botswana',
    },
    {
        value: 'bouvet island',
        label: 'Bouvet Island',
    },
    {
        value: 'brazil',
        label: 'Brazil',
    },
    {
        value: 'british indian ocean territory',
        label: 'British Indian Ocean Territory',
    },
    {
        value: 'brunei darussalam',
        label: 'Brunei Darussalam',
    },
    {
        value: 'bulgaria',
        label: 'Bulgaria',
    },
    {
        value: 'burkina faso',
        label: 'Burkina Faso',
    },
    {
        value: 'burundi',
        label: 'Burundi',
    },
    {
        value: 'cambodia',
        label: 'Cambodia',
    },
    {
        value: 'cameroon',
        label: 'Cameroon',
    },
    {
        value: 'canada',
        label: 'Canada',
    },
    {
        value: 'canary islands',
        label: 'Canary Islands',
    },
    {
        value: 'cape verde',
        label: 'Cape Verde',
    },
    {
        value: 'cayman islands',
        label: 'Cayman Islands',
    },
    {
        value: 'central african republic',
        label: 'Central African Republic',
    },
    {
        value: 'chad',
        label: 'Chad',
    },
    {
        value: 'chile',
        label: 'Chile',
    },
    {
        value: 'china',
        label: 'China',
    },
    {
        value: 'christmas island',
        label: 'Christmas Island',
    },
    {
        value: 'cocos islands',
        label: 'Cocos Islands',
    },
    {
        value: 'colombia',
        label: 'Colombia',
    },
    {
        value: 'comoros',
        label: 'Comoros',
    },
    {
        value: 'congo',
        label: 'Congo',
    },
    {
        value: 'congo, the democratic republic of the',
        label: 'Congo, The Democratic Republic of the',
    },
    {
        value: 'cook islands',
        label: 'Cook Islands',
    },
    {
        value: 'costa rica',
        label: 'Costa Rica',
    },
    {
        value: "cote d'ivoire",
        label: "Cote D'Ivoire",
    },
    {
        value: 'croatia',
        label: 'Croatia',
    },
    {
        value: 'cuba',
        label: 'Cuba',
    },
    {
        value: 'cyprus',
        label: 'Cyprus',
    },
    {
        value: 'czech republic',
        label: 'Czech Republic',
    },
    {
        value: 'denmark',
        label: 'Denmark',
    },
    {
        value: 'djibouti',
        label: 'Djibouti',
    },
    {
        value: 'dominica',
        label: 'Dominica',
    },
    {
        value: 'dominican republic',
        label: 'Dominican Republic',
    },
    {
        value: 'ecuador',
        label: 'Ecuador',
    },
    {
        value: 'egypt',
        label: 'Egypt',
    },
    {
        value: 'el salvador',
        label: 'El Salvador',
    },
    {
        value: 'equatorial guinea',
        label: 'Equatorial Guinea',
    },
    {
        value: 'eritrea',
        label: 'Eritrea',
    },
    {
        value: 'estonia',
        label: 'Estonia',
    },
    {
        value: 'ethiopia',
        label: 'Ethiopia',
    },
    {
        value: 'falkland islands (malvinas)',
        label: 'Falkland Islands (Malvinas)',
    },
    {
        value: 'faroe islands',
        label: 'Faroe Islands',
    },
    {
        value: 'fiji',
        label: 'Fiji',
    },
    {
        value: 'finland',
        label: 'Finland',
    },
    {
        value: 'france',
        label: 'France',
    },
    {
        value: 'french guiana',
        label: 'French Guiana',
    },
    {
        value: 'french polynesia',
        label: 'French Polynesia',
    },
    {
        value: 'french southern territories',
        label: 'French Southern Territories',
    },
    {
        value: 'gabon',
        label: 'Gabon',
    },
    {
        value: 'gambia',
        label: 'Gambia',
    },
    {
        value: 'georgia',
        label: 'Georgia',
    },
    {
        value: 'germany',
        label: 'Germany',
    },
    {
        value: 'ghana',
        label: 'Ghana',
    },
    {
        value: 'gibraltar',
        label: 'Gibraltar',
    },
    {
        value: 'greece',
        label: 'Greece',
    },
    {
        value: 'greenland',
        label: 'Greenland',
    },
    {
        value: 'grenada',
        label: 'Grenada',
    },
    {
        value: 'guadeloupe',
        label: 'Guadeloupe',
    },
    {
        value: 'guam',
        label: 'Guam',
    },
    {
        value: 'guatemala',
        label: 'Guatemala',
    },
    {
        value: 'guernsey',
        label: 'Guernsey',
    },
    {
        value: 'guinea',
        label: 'Guinea',
    },
    {
        value: 'guinea-bissau',
        label: 'Guinea-Bissau',
    },
    {
        value: 'guyana',
        label: 'Guyana',
    },
    {
        value: 'haiti',
        label: 'Haiti',
    },
    {
        value: 'heard island and mcdonald islands',
        label: 'Heard Island and Mcdonald Islands',
    },
    {
        value: 'honduras',
        label: 'Honduras',
    },
    {
        value: 'hong kong',
        label: 'Hong Kong',
    },
    {
        value: 'hungary',
        label: 'Hungary',
    },
    {
        value: 'iceland',
        label: 'Iceland',
    },
    {
        value: 'india',
        label: 'India',
    },
    {
        value: 'indonesia',
        label: 'Indonesia',
    },
    {
        value: 'iran',
        label: 'Iran',
    },
    {
        value: 'iraq',
        label: 'Iraq',
    },
    {
        value: 'ireland',
        label: 'Ireland',
    },
    {
        value: 'isle of man',
        label: 'Isle of Man',
    },
    {
        value: 'israel',
        label: 'Israel',
    },
    {
        value: 'italy',
        label: 'Italy',
    },
    {
        value: 'jamaica',
        label: 'Jamaica',
    },
    {
        value: 'japan',
        label: 'Japan',
    },
    {
        value: 'jersey',
        label: 'Jersey',
    },
    {
        value: 'jordan',
        label: 'Jordan',
    },
    {
        value: 'kazakhstan',
        label: 'Kazakhstan',
    },
    {
        value: 'kenya',
        label: 'Kenya',
    },
    {
        value: 'kiribati',
        label: 'Kiribati',
    },
    {
        value: 'korea north',
        label: 'Korea North',
    },
    {
        value: 'korea south',
        label: 'Korea South',
    },
    {
        value: 'kuwait',
        label: 'Kuwait',
    },
    {
        value: 'kyrgyzstan',
        label: 'Kyrgyzstan',
    },
    {
        value: 'laos',
        label: 'Laos',
    },
    {
        value: 'latvia',
        label: 'Latvia',
    },
    {
        value: 'lebanon',
        label: 'Lebanon',
    },
    {
        value: 'lesotho',
        label: 'Lesotho',
    },
    {
        value: 'liberia',
        label: 'Liberia',
    },
    {
        value: 'libya',
        label: 'Libya',
    },
    {
        value: 'liechtenstein',
        label: 'Liechtenstein',
    },
    {
        value: 'lithuania',
        label: 'Lithuania',
    },
    {
        value: 'luxembourg',
        label: 'Luxembourg',
    },
    {
        value: 'macao',
        label: 'Macao',
    },
    {
        value: 'macedonia',
        label: 'Macedonia',
    },
    {
        value: 'madagascar',
        label: 'Madagascar',
    },
    {
        value: 'malawi',
        label: 'Malawi',
    },
    {
        value: 'malaysia',
        label: 'Malaysia',
    },
    {
        value: 'maldives',
        label: 'Maldives',
    },
    {
        value: 'mali',
        label: 'Mali',
    },
    {
        value: 'malta',
        label: 'Malta',
    },
    {
        value: 'marshall islands',
        label: 'Marshall Islands',
    },
    {
        value: 'martinique',
        label: 'Martinique',
    },
    {
        value: 'mauritania',
        label: 'Mauritania',
    },
    {
        value: 'mauritius',
        label: 'Mauritius',
    },
    {
        value: 'mayotte',
        label: 'Mayotte',
    },
    {
        value: 'mexico',
        label: 'Mexico',
    },
    {
        value: 'micronesia, federated states of',
        label: 'Micronesia, Federated States of',
    },
    {
        value: 'moldova',
        label: 'Moldova',
    },
    {
        value: 'monaco',
        label: 'Monaco',
    },
    {
        value: 'mongolia',
        label: 'Mongolia',
    },
    {
        value: 'montserrat',
        label: 'Montserrat',
    },
    {
        value: 'morocco',
        label: 'Morocco',
    },
    {
        value: 'mozambique',
        label: 'Mozambique',
    },
    {
        value: 'myanmar',
        label: 'Myanmar',
    },
    {
        value: 'namibia',
        label: 'Namibia',
    },
    {
        value: 'nauru',
        label: 'Nauru',
    },
    {
        value: 'nepal',
        label: 'Nepal',
    },
    {
        value: 'netherlands',
        label: 'Netherlands',
    },
    {
        value: 'netherlands antilles',
        label: 'Netherlands Antilles',
    },
    {
        value: 'new caledonia',
        label: 'New Caledonia',
    },
    {
        value: 'new zealand',
        label: 'New Zealand',
    },
    {
        value: 'nicaragua',
        label: 'Nicaragua',
    },
    {
        value: 'niger',
        label: 'Niger',
    },
    {
        value: 'nigeria',
        label: 'Nigeria',
    },
    {
        value: 'niue',
        label: 'Niue',
    },
    {
        value: 'norfolk island',
        label: 'Norfolk Island',
    },
    {
        value: 'northern mariana islands',
        label: 'Northern Mariana Islands',
    },
    {
        value: 'norway',
        label: 'Norway',
    },
    {
        value: 'oman',
        label: 'Oman',
    },
    {
        value: 'pakistan',
        label: 'Pakistan',
    },
    {
        value: 'palau',
        label: 'Palau',
    },
    {
        value: 'palestine',
        label: 'Palestine',
    },
    {
        value: 'panama',
        label: 'Panama',
    },
    {
        value: 'papua new guinea',
        label: 'Papua New Guinea',
    },
    {
        value: 'paraguay',
        label: 'Paraguay',
    },
    {
        value: 'peru',
        label: 'Peru',
    },
    {
        value: 'philippines',
        label: 'Philippines',
    },
    {
        value: 'pitcairn',
        label: 'Pitcairn',
    },
    {
        value: 'poland',
        label: 'Poland',
    },
    {
        value: 'portugal',
        label: 'Portugal',
    },
    {
        value: 'puerto rico',
        label: 'Puerto Rico',
    },
    {
        value: 'qatar',
        label: 'Qatar',
    },
    {
        value: 'reunion',
        label: 'Reunion',
    },
    {
        value: 'romania',
        label: 'Romania',
    },
    {
        value: 'russian federation',
        label: 'Russian Federation',
    },
    {
        value: 'rwanda',
        label: 'Rwanda',
    },
    {
        value: 'saint helena',
        label: 'Saint Helena',
    },
    {
        value: 'saint kitts and nevis',
        label: 'Saint Kitts and Nevis',
    },
    {
        value: 'saint lucia',
        label: 'Saint Lucia',
    },
    {
        value: 'saint pierre and miquelon',
        label: 'Saint Pierre and Miquelon',
    },
    {
        value: 'saint vincent and the grenadines',
        label: 'Saint Vincent and the Grenadines',
    },
    {
        value: 'samoa',
        label: 'Samoa',
    },
    {
        value: 'san marino',
        label: 'San Marino',
    },
    {
        value: 'sao tome and principe',
        label: 'Sao Tome and Principe',
    },
    {
        value: 'saudi arabia',
        label: 'Saudi Arabia',
    },
    {
        value: 'senegal',
        label: 'Senegal',
    },
    {
        value: 'serbia and montenegro',
        label: 'Serbia and Montenegro',
    },
    {
        value: 'seychelles',
        label: 'Seychelles',
    },
    {
        value: 'sierra leone',
        label: 'Sierra Leone',
    },
    {
        value: 'singapore',
        label: 'Singapore',
    },
    {
        value: 'slovakia',
        label: 'Slovakia',
    },
    {
        value: 'slovenia',
        label: 'Slovenia',
    },
    {
        value: 'solomon islands',
        label: 'Solomon Islands',
    },
    {
        value: 'somalia',
        label: 'Somalia',
    },
    {
        value: 'south africa',
        label: 'South Africa',
    },
    {
        value: 'south georgia and the south sandwich islands',
        label: 'South Georgia and the South Sandwich Islands',
    },
    {
        value: 'spain',
        label: 'Spain',
    },
    {
        value: 'sri lanka',
        label: 'Sri Lanka',
    },
    {
        value: 'sudan',
        label: 'Sudan',
    },
    {
        value: 'suriname',
        label: 'Suriname',
    },
    {
        value: 'svalbard and jan mayen',
        label: 'Svalbard and Jan Mayen',
    },
    {
        value: 'swaziland',
        label: 'Swaziland',
    },
    {
        value: 'sweden',
        label: 'Sweden',
    },
    {
        value: 'switzerland',
        label: 'Switzerland',
    },
    {
        value: 'syrian arab republic',
        label: 'Syrian Arab Republic',
    },
    {
        value: 'taiwan, province of china',
        label: 'Taiwan, Province of China',
    },
    {
        value: 'tajikistan',
        label: 'Tajikistan',
    },
    {
        value: 'tanzania, united republic of',
        label: 'Tanzania, United Republic of',
    },
    {
        value: 'thailand',
        label: 'Thailand',
    },
    {
        value: 'timor-leste',
        label: 'Timor-Leste',
    },
    {
        value: 'togo',
        label: 'Togo',
    },
    {
        value: 'tokelau',
        label: 'Tokelau',
    },
    {
        value: 'tonga',
        label: 'Tonga',
    },
    {
        value: 'trinidad and tobago',
        label: 'Trinidad and Tobago',
    },
    {
        value: 'tunisia',
        label: 'Tunisia',
    },
    {
        value: 'turkey',
        label: 'Turkey',
    },
    {
        value: 'turkmenistan',
        label: 'Turkmenistan',
    },
    {
        value: 'turks and caicos islands',
        label: 'Turks and Caicos Islands',
    },
    {
        value: 'tuvalu',
        label: 'Tuvalu',
    },
    {
        value: 'uganda',
        label: 'Uganda',
    },
    {
        value: 'ukraine',
        label: 'Ukraine',
    },
    {
        value: 'united arab emirates',
        label: 'United Arab Emirates',
    },
    {
        value: 'united kingdom',
        label: 'United Kingdom',
    },
    {
        value: 'uruguay',
        label: 'Uruguay',
    },
    {
        value: 'uzbekistan',
        label: 'Uzbekistan',
    },
    {
        value: 'vanuatu',
        label: 'Vanuatu',
    },
    {
        value: 'vatican city state',
        label: 'Vatican City State',
    },
    {
        value: 'venezuela',
        label: 'Venezuela',
    },
    {
        value: 'vietnam',
        label: 'Vietnam',
    },
    {
        value: 'virgin islands, british',
        label: 'Virgin Islands, British',
    },
    {
        value: 'virgin islands, u.s.',
        label: 'Virgin Islands, U.S.',
    },
    {
        value: 'wallis and futuna',
        label: 'Wallis and Futuna',
    },
    {
        value: 'western sahara',
        label: 'Western Sahara',
    },
    {
        value: 'yemen',
        label: 'Yemen',
    },
    {
        value: 'zambia',
        label: 'Zambia',
    },
    {
        value: 'zimbabwe',
        label: 'Zimbabwe',
    },
];
export const states = [
    {
        value: 'AK',
        label: 'AK',
        state: 'Alaska',
    },
    {
        value: 'AL',
        label: 'AL',
        state: 'Alabama',
    },
    {
        value: 'AR',
        label: 'AR',
        state: 'Arkansas',
    },
    {
        value: 'AS',
        label: 'AS',
        state: 'American Samoa',
    },
    {
        value: 'AZ',
        label: 'AZ',
        state: 'Arizona',
    },
    {
        value: 'CA',
        label: 'CA',
        state: 'California',
    },
    {
        value: 'CO',
        label: 'CO',
        state: 'Colorado',
    },
    {
        value: 'CT',
        label: 'CT',
        state: 'Connecticut',
    },
    {
        value: 'DC',
        label: 'DC',
        state: 'District of Columbia',
    },
    {
        value: 'DE',
        label: 'DE',
        state: 'Delaware',
    },
    {
        value: 'FL',
        label: 'FL',
        state: 'Florida',
    },
    {
        value: 'GA',
        label: 'GA',
        state: 'Georgia',
    },
    {
        value: 'GU',
        label: 'GU',
        state: '',
    },
    {
        value: 'HI',
        label: 'HI',
        state: 'Hawaii',
    },
    {
        value: 'IA',
        label: 'IA',
        state: 'Iowa',
    },
    {
        value: 'ID',
        label: 'ID',
        state: 'Idaho',
    },
    {
        value: 'IL',
        label: 'IL',
        state: 'Illinois',
    },
    {
        value: 'IN',
        label: 'IN',
        state: 'Indiana',
    },
    {
        value: 'KS',
        label: 'KS',
        state: 'Kansas',
    },
    {
        value: 'KY',
        label: 'KY',
        state: 'Kentucky',
    },
    {
        value: 'LA',
        label: 'LA',
        state: 'Louisiana',
    },
    {
        value: 'MA',
        label: 'MA',
        state: 'Massachusetts',
    },
    {
        value: 'MD',
        label: 'MD',
        state: 'Maryland',
    },
    {
        value: 'ME',
        label: 'ME',
        state: 'Maine',
    },
    {
        value: 'MI',
        label: 'MI',
        state: 'Michigan',
    },
    {
        value: 'MN',
        label: 'MN',
        state: 'Minnesota',
    },
    {
        value: 'MO',
        label: 'MO',
        state: 'Missouri',
    },
    {
        value: 'MS',
        label: 'MS',
        state: 'Mississippi',
    },
    {
        value: 'MT',
        label: 'MT',
        state: 'Montana',
    },
    {
        value: 'NC',
        label: 'NC',
        state: 'North Carolina',
    },
    {
        value: 'ND',
        label: 'ND',
        state: 'North Dakota',
    },
    {
        value: 'NE',
        label: 'NE',
        state: 'Nebraska',
    },
    {
        value: 'NH',
        label: 'NH',
        state: 'New Hampshire',
    },
    {
        value: 'NJ',
        label: 'NJ',
        state: 'New Jersey',
    },
    {
        value: 'NM',
        label: 'NM',
        state: 'New Mexico',
    },
    {
        value: 'NV',
        label: 'NV',
        state: 'Nevada',
    },
    {
        value: 'NY',
        label: 'NY',
        state: 'New York',
    },
    {
        value: 'OH',
        label: 'OH',
        state: 'Ohio',
    },
    {
        value: 'OK',
        label: 'OK',
        state: 'Oklahoma',
    },
    {
        value: 'OR',
        label: 'OR',
        state: 'Oregon',
    },
    {
        value: 'PA',
        label: 'PA',
        state: 'Pennsylvania',
    },
    {
        value: 'PR',
        label: 'PR',
        state: 'Puerto Rico',
    },
    {
        value: 'RI',
        label: 'RI',
        state: 'Rhode Island',
    },
    {
        value: 'SC',
        label: 'SC',
        state: 'South Carolina',
    },
    {
        value: 'SD',
        label: 'SD',
        state: 'South Dakota',
    },
    {
        value: 'TN',
        label: 'TN',
        state: 'Tennessee',
    },
    {
        value: 'TX',
        label: 'TX',
        state: 'Texas',
    },
    {
        value: 'UT',
        label: 'UT',
        state: 'Utah',
    },
    {
        value: 'VA',
        label: 'VA',
        state: 'Virginia',
    },
    {
        value: 'VI',
        label: 'VI',
        state: 'Virgin Islands',
    },
    {
        value: 'VT',
        label: 'VT',
        state: 'Vermont',
    },
    {
        value: 'WA',
        label: 'WA',
        state: 'Washington',
    },
    {
        value: 'WI',
        label: 'WI',
        state: 'Wisconsin',
    },
    {
        value: 'WV',
        label: 'WV',
        state: 'West Virginia',
    },
    {
        value: 'WY',
        label: 'WY',
        state: 'Wyoming',
    },
];
