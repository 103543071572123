// import * as instances from './instances'
import {ZipInstance, HealthInstance, QuoteInstance} from './instances';
import {toJS} from 'mobx';
import {RootStore} from '../store/Store';

const instances = {
    ZipInstance,
    HealthInstance,
    QuoteInstance,
};

export const utmInjector = (store: RootStore) => {
    Object.values(instances).forEach((instance) =>
        instance.interceptors.request.use((config) => {
            if (config.data) {
                config.data = {...config.data, utm: toJS(store.userData.utm)};
            }
            return config;
        }),
    );
};
