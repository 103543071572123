import {UpdateFunction} from '../store/UserData/types';
import {runInAction} from 'mobx';

const updateCustomField = (customField: string) =>
    function (fieldName: string, val?: unknown): UpdateFunction {
        if (this[customField][fieldName] !== undefined) {
            const crateUpdateFunction =
                (fieldName: string): UpdateFunction =>
                (val: unknown) =>
                    runInAction(() => (this[customField][fieldName] = val));

            const updFunction = crateUpdateFunction(fieldName);

            if (val === undefined) return updFunction;
            else updFunction(val);
        }
    };

export default updateCustomField;
