import {makeAutoObservable} from 'mobx';
import {storeVersion} from '../config';
import {serializable} from './sync/serialization';

class VersionStore {
    @serializable()
    siteVersion = '0.0.3';

    @serializable()
    storeVersion = storeVersion;

    constructor() {
        makeAutoObservable(this);
    }
}

export default VersionStore;
