import {makeAutoObservable} from 'mobx';

import updateField from '../../helpers/updateField';
import getField from '../../helpers/getField';
import Phase4Api from '../../api/phases/phase4Api';
import dayjs from 'dayjs';
import {type UserData} from '.';
import {serializable} from '../sync/serialization';

const NEW_LEAD_TIME_MS = 5 * 60 * 1000;

class Phase4 {
    private parentStore: UserData;

    @serializable()
    leadCreationTimestamp = 0;

    @serializable()
    hasSentViewQuotesEvent = false;

    constructor(parentStore: UserData) {
        makeAutoObservable(this);
        this.parentStore = parentStore;
    }

    isNewLead(): boolean {
        // considered new if created in last 5 minutes
        const isNewLead =
            !this.hasSentViewQuotesEvent && dayjs().valueOf() - this.leadCreationTimestamp <= NEW_LEAD_TIME_MS;
        // following flag prevents resending events when page is quickly reloaded
        // since isNewLead is called when sending the event to gtm, reset flag within here
        if (!this.hasSentViewQuotesEvent) this.updateField('hasSentViewQuotesEvent', true);
        return isNewLead;
    }

    sendPhoneName(): void {
        const {uuid, email, firstName, lastName, phone} = this.parentStore;

        Phase4Api.sendPhoneName({
            uuid,
            email,
            first_name: firstName,
            last_name: lastName,
            phone_number: phone,
        });
    }

    async sendConsent(firstName: string, lastName: string, dob: string, uuid: string): Promise<object> {
        return Phase4Api.sendConsent({
            first_name: firstName,
            last_name: lastName,
            dob: dob,
            userId: uuid,
        });
    }

    updateField = updateField.bind(this);
    getField = getField.bind(this);
}

export default Phase4;
