import {makeAutoObservable} from 'mobx';
import {serializable} from './sync/serialization';

class ConstStore {
    @serializable()
    helpEmail = 'team@getamplifylife.com';

    @serializable()
    helpPhone = '855-687-4300';

    @serializable()
    fartherHelpPhone = '855-630-0212';

    @serializable()
    expertTeamPhone = '855-687-4300';

    @serializable()
    landing = 'https://www.getamplifylife.com/';

    constructor() {
        makeAutoObservable(this);
    }
}

export default ConstStore;
