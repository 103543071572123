import {makeAutoObservable} from 'mobx';
import {CookieStorage} from 'cookie-storage';
import {v4 as uuidv4} from 'uuid';
import UIStore from './UIStore';
import UserData from './UserData';
import CalculatorStore from './CalculatorStore';
import ConstStore from './ConstStore';
import VersionStore from './VersionStore';
import LogStore from './LogStore';
import {AsyncTrunk} from './sync';
import {storeVersion} from '../config';
import CommonApi from '../api/phases/commonApi';
import Feature from '../components/FeatureFlag/Flags';
import dayjs from 'dayjs';
import {serializable, serialize} from './sync/serialization';

const COOKIE_USER_ID = 'amplifyUserUuid';
const NERDWALLET_USER_ID = 'c3311dba-770b-44d2-af93-10469994c97';

type StoreInitParams = {
    hubId?: string;
    userId?: string;
    experiment?: Partial<Record<Feature, boolean>>;
};

export class RootStore {
    @serializable()
    uiStore: UIStore;

    @serializable()
    userData: UserData;

    @serializable()
    calcStore: CalculatorStore;

    @serializable()
    constInfo: ConstStore;

    @serializable()
    versionStore: VersionStore;

    @serializable()
    logStore: LogStore;

    @serializable('userId')
    private _userId = '';

    public get userId(): string {
        return this._userId;
    }

    private set userId(id: string) {
        this._userId = id;
    }

    static async init({experiment = {}, userId, hubId}: StoreInitParams = {}): Promise<RootStore> {
        const store = new RootStore();
        await store.init({experiment, userId, hubId});
        return store;
    }

    constructor() {
        makeAutoObservable(this);

        this.uiStore = new UIStore(this);
        this.userData = new UserData(this);
        this.constInfo = new ConstStore();
        this.versionStore = new VersionStore();
        this.logStore = new LogStore(this);
        this.calcStore = new CalculatorStore(this);
    }

    async init({experiment, userId, hubId}: StoreInitParams) {
        const cookie = new CookieStorage();
        const cookieUserId = cookie.getItem(COOKIE_USER_ID);
        const id = (userId !== NERDWALLET_USER_ID && userId) || cookieUserId || uuidv4();

        // We want to always update the cookie upon session load no matter the userId
        cookie.setItem(COOKIE_USER_ID, id, {
            path: '/',
            ...(process.env.NODE_ENV === 'production' ? {domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN} : {}),
            secure: true,
            sameSite: 'None',
            expires: dayjs().add(1, 'year').toDate(),
        });

        const trunk = new AsyncTrunk(this, {storage: localStorage});
        await trunk.init();

        // AsyncTrunk should have loaded the store from localStorage at this point
        if (id !== this.userId || this.versionStore.storeVersion !== storeVersion) {
            console.info('New user session or store version detected. Clearing localStorage cache.');
            this.reset();
        }

        this.userId = id;

        if (hubId) {
            await this.userData.loadContactData(hubId);
        }

        this.userData.experiment = experiment;
    }

    async saveSession(): Promise<void> {
        const data = serialize(this);

        try {
            await CommonApi.updateSession(data);
        } catch (e) {
            console.error('Error updating session', e);
        }
    }

    reset() {
        this.userData.dispose();

        this.uiStore = new UIStore(this);
        this.userData = new UserData(this);
        this.constInfo = new ConstStore();
        this.versionStore = new VersionStore();
        this.logStore = new LogStore(this);
        this.calcStore = new CalculatorStore(this);
    }
}
