const applyOverscrollClass = (shouldApply: boolean): void => {
    const body = document?.body;
    const html = document?.documentElement;

    if (shouldApply) {
        body?.classList?.add('disable-overscroll');
        html?.classList?.add('disable-overscroll');
    } else {
        body?.classList?.remove('disable-overscroll');
        html?.classList?.remove('disable-overscroll');
    }
};

export default applyOverscrollClass;
