/* eslint-disable @typescript-eslint/no-explicit-any */
import {action} from 'mobx';

export function serialize(obj: any): any {
    const serialized: any = {};
    for (const key in obj._serializable) {
        const info = obj._serializable[key];
        const value = obj[key];
        serialized[info.serializedName || key] =
            typeof value === 'object' && value !== null && value._serializable ? serialize(value) : value;
    }

    return serialized;
}

function _deserialize(store: any, json: any): void {
    for (const key in store._serializable) {
        const info = store._serializable[key];
        if (!info.isComputed && json.hasOwnProperty(info.serializedName || key)) {
            const value = json[info.serializedName || key];
            if (typeof value === 'object' && value !== null && store[key]?._serializable) {
                deserialize(store[key], value); // Recursively deserialize nested stores
            } else {
                store[key] = value;
            }
        }
    }
}

export const deserialize = action(_deserialize);

export function serializable(serializedName?: string) {
    return function (target: any, propertyName: string, descriptor?: PropertyDescriptor) {
        if (!target._serializable) {
            target._serializable = {};
        }

        // Check if the decorator is applied to a method or setter
        if (descriptor && (typeof descriptor.value === 'function' || typeof descriptor.set === 'function')) {
            throw new Error(`serialized decorator cannot be used on methods or setters: ${propertyName}`);
        }

        const isComputed = descriptor && typeof descriptor.get === 'function';
        target._serializable[propertyName] = {
            serializedName: serializedName || propertyName,
            isComputed: isComputed,
        };
    };
}
