/* eslint-disable @typescript-eslint/no-explicit-any */
import {autorun, IReactionDisposer} from 'mobx';
import {deserialize, serialize} from './serialization';

export const KeyVersions = '__mobx_sync_versions__';
export const KeyDefaultKey = '__mobx_sync__';
export const KeyActionName = '__PERSIST__';
export const KeyNodeVersion = '__mobx_sync_this__';
export const KeyIgnores = '__mobx_ignores__';
export const KeyFormat = '__mobx_sync_format__';
export const KeyInject = '__mobx_sync_inject__';

/**
 * The async storage API
 */
export interface AsyncStorage {
    getItem(key: string): Promise<string | null>;
    setItem(key: string, value: string): Promise<void>;
    removeItem(key: string): Promise<void>;
}

export interface SyncStorage {
    getItem(key: string): string | null;
    setItem(key: string, value: string): void;
    removeItem(key: string): void;
}

/**
 * the async trunk initial options
 */
export interface AsyncTrunkOptions {
    /**
     * storage, both AsyncStorage and SyncStorage is supported,
     * default is localStorage
     */
    storage?: AsyncStorage | SyncStorage;
    /**
     * the custom persisted key in storage,
     * default is KeyDefaultKey
     */
    storageKey?: string;
    /**
     * delay milliseconds for run the reaction for mobx,
     * default is 0
     */
    delay?: number;

    /**
     * error callback
     * @param error
     */
    onError?: (error: any) => void;
}

export class AsyncTrunk {
    disposer!: IReactionDisposer;
    private store: any;
    private storage: AsyncStorage | SyncStorage;
    readonly storageKey: string;
    readonly delay: number;
    readonly onError: (error: any) => void;

    constructor(
        store: any,
        {storage = localStorage, storageKey = KeyDefaultKey, delay = 0, onError = () => void 0}: AsyncTrunkOptions = {},
    ) {
        this.store = store;
        this.storage = storage;
        this.storageKey = storageKey;
        this.delay = delay;
        this.onError = onError;
    }

    async persist() {
        try {
            await this.storage.setItem(this.storageKey, JSON.stringify(serialize(this.store)));
        } catch (reason) {
            this.onError(reason);
        }
    }

    /**
     * init the trunk async
     */
    async init(initialState?: any) {
        try {
            const data = await this.storage.getItem(this.storageKey);
            if (data) {
                deserialize(this.store, JSON.parse(data));
            }
        } catch {
            // DO nothing
        }
        if (initialState) {
            deserialize(this.store, initialState);
        }
        // persist before listen change
        this.persist();
        this.disposer = autorun(this.persist.bind(this), {
            name: KeyActionName,
            delay: this.delay,
            onError: this.onError,
        });
    }

    async clear() {
        return this.storage.removeItem(this.storageKey);
    }

    updateStore(store: any) {
        this.store = store;
        return this.persist();
    }
}
