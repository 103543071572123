// Based on age and gender, a different 'comfortable amount' needs to be used. Table is
// For Male, <=24 year old, 300 or above
// <=35, 400 or above
// <=43, 500 or above
// <=50, 600 or above
// rest 700 or above
// For Female, <=32, 300
// <=43, 400
// <=50, 500
// rest 600

import {overallHealthConditions} from '../store/structures/selects';

// index 0 is at age 20
export const lowerContributionLimitMale = [
    300, 300, 300, 300, 300, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400, 500, 500, 500, 500, 500, 500, 500,
    500, 600, 600, 600, 600, 600, 600, 600, 700, 700, 700, 700, 700,
];

export const lowerContributionLimitFemale = [
    300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 400, 400, 400, 400, 400, 400, 400, 400, 400, 400,
    400, 500, 500, 500, 500, 500, 500, 500, 600, 600, 600, 600, 600,
];

// These numbers are based on Ameritas numbers we have
// CV is roughly about 70% of our cached data
export const ameritasToEquitableCoefficient = 0.7;

// Death benefit changes from about 60% at age 20 to 90% at age 55. Put a buffer to put DB we display below actual Equitable value
export const deathBenefitMultiplier = [
    0.55, 0.56, 0.57, 0.58, 0.59, 0.6, 0.6, 0.61, 0.62, 0.63, 0.64, 0.65, 0.65, 0.66, 0.66, 0.67, 0.68, 0.69, 0.7, 0.71,
    0.72, 0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79, 0.8, 0.8, 0.81, 0.82, 0.83, 0.83, 0.84, 0.85,
];

// Criteria constants for qualification of Amplify VUL
export const amplifyVulOfferingStates = new Set(['Arizona']);
export const amplifyVulMinimumHouseholdIncome = 50000;
export const amplifyVulOverallHealth = new Set([overallHealthConditions.average, overallHealthConditions.excellent]);
