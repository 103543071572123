import {IReactionDisposer, makeAutoObservable, reaction, runInAction} from 'mobx';
import Phase1 from './phase1';
import Phase2 from './phase2';
import Phase3 from './phase3';
import Phase4 from './phase4';
import updateField from '../../helpers/updateField';
import getField from '../../helpers/getField';
import {prodMode, storeVersion} from '../../config';
import {type RootStore} from '../Store';
import * as Sentry from '@sentry/react';
import Feature from '../../components/FeatureFlag/Flags';
import {serializable} from '../sync/serialization';

export interface InitialProductRecommendation {
    initialProductRecommendation: {
        recommendedProductType: string;
        initialSelectedTab: string;
        resultBest: unknown;
        resultRetirement: unknown;
        resultTemporary: unknown;
        resultVul: unknown;
    };
}

export class UserData {
    parentStore: RootStore;

    @serializable()
    phase1: Phase1;

    @serializable()
    phase2: Phase2;

    @serializable()
    phase3: Phase3;

    @serializable()
    phase4: Phase4;

    @serializable()
    email = '';

    @serializable()
    firstName = '';

    @serializable()
    lastName = '';

    @serializable()
    phone = '';

    @serializable()
    consent = '';

    @serializable()
    consentAuthUrl = '';

    @serializable()
    consentTimestamp: number | null = null;

    @serializable()
    customerId = ''; // id returned from quote approval

    @serializable()
    startRef: string | null = null;

    @serializable()
    utm = {
        campaign: '',
        content: '',
        medium: '',
        source: '',
        term: '',
        origin: '',
        adgroup: '',
    };

    @serializable()
    referral = {
        rrWcid: '',
        rrWcidTtl: '',
        referralCode: '',
        partnerUserId: '',
        callbackUrl: '',
    };

    @serializable()
    partners = {
        tuneId: '',
        offerId: '',
        offerName: '',
        affiliateId: '',
        source: '',
    };

    @serializable()
    recommendedProductTypeMx = 'No recommendation made';

    @serializable()
    selectedProductTypeMx = 'No selection made';

    @serializable()
    recommendationOverridden = false;

    @serializable()
    wtStartTime: number | null = null;

    @serializable()
    wtLeadFormTime: number | null = null;

    // Used for A/B/n testing analysis
    @serializable('experiment')
    private _experiment: Record<string, boolean> = {};

    private emailReactionDisposer: IReactionDisposer;

    constructor(parentStore: RootStore) {
        makeAutoObservable(this);
        this.parentStore = parentStore;

        this.phase1 = new Phase1();
        this.phase2 = new Phase2();
        this.phase3 = new Phase3(this);
        this.phase4 = new Phase4(this);

        this.emailReactionDisposer = reaction(
            () => this.email,
            (email) => {
                Sentry.setUser({email});
            },
        );
    }

    @serializable()
    get version() {
        return [prodMode ? 'prod' : 'staging', storeVersion].join('_');
    }

    @serializable()
    get uuid() {
        return this.parentStore.userId;
    }

    get experiment() {
        return this._experiment;
    }

    set experiment(flags: Record<string, boolean>) {
        this._experiment = flags;
    }

    resetPhase1() {
        this.phase1 = new Phase1();
    }

    resetPhase2() {
        this.phase2 = new Phase2();
    }

    resetPhase3() {
        this.phase3 = new Phase3(this);
    }

    async loadContactData(contactId: string): Promise<void> {
        try {
            const resp = await fetch(`/api/getContactData/${contactId}`);
            const contact = await resp.json();

            runInAction(() => {
                this.firstName = contact.firstName;
                this.lastName = contact.lastName;
                this.email = contact.email;
                this.phone = contact.phone;
            });
        } catch (e) {
            console.error('Could not fetch contact data', e);
        }
    }

    updateField = updateField.bind(this);
    getField = getField.bind(this);

    initAutoDefaultPages() {
        console.log('initAutoDefaultPages ' + true);
        const setVaue = localStorage.getItem('setDeaultValue');
        if (setVaue !== 'false') {
            this.phase1.updateField('military')(false);
            this.phase1.updateField('travellingOut')(false);
            this.phase1.updateField('usCitizen')(true);
            this.phase1.updateField('employmentStatus')('employed');
            this.phase2.updateField('aids')(false);
            localStorage.setItem('setDeaultValue', 'false');
        }
    }

    dispose() {
        this.emailReactionDisposer();
        this.phase3.dispose();
    }
}

export default UserData;
