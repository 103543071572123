import requestHelper from '../requestHelper';
import {AthenaCustomerInstance, QuoteInstance} from '../instances';
import {ResponseStatus} from '../types';

export default class Phase4Api {
    static sendPhoneName = async (data: {
        uuid: string;
        email: string;
        first_name: string;
        last_name: string;
        phone_number: string;
    }): Promise<undefined> => {
        const response = await requestHelper(data, `/phone-name`, QuoteInstance, 'POST');

        if (response.status === ResponseStatus.error) return null;

        return undefined;
    };

    static sendConsent = async (data: {
        first_name: string;
        last_name: string;
        dob: string;
        userId: string;
    }): Promise<object> => {
        const {first_name, last_name, dob, userId} = data;
        const urlPath = `/${first_name}/${last_name}/${dob}/consent`;
        const response = await requestHelper({userId: userId}, urlPath, AthenaCustomerInstance, 'POST');
        if (response.status === ResponseStatus.error) return null;
        return response;
    };
}
