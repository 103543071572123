export type UpdateFunction = (val: unknown) => void;

export enum diagCond {
    Alcohol = 'Alcohol abuse',
    Drug = 'Drug abuse',
    Asthma = 'Asthma',
    Cancer = 'Cancer',
    PsychDisorder = 'Psychological disorder (anxiety, depression, bipolar)',
    Diabetes = 'Diabetes',
    HeartAttack = 'Heart attack',
    HighCholesterol = 'High cholesterol',
    BloodPressure = 'High blood pressure',
    SleepApnea = 'Sleep apnea',
    Stroke = 'Stroke',
    OtherCondition = 'Other serious condition',
    None = 'None',
}

export enum anyCond {
    AlcoholOrDrug = 'Alcohol and/or Drug Dependence',
    DUI = 'DUI',
    SuspendedLicense = 'Suspended License',
    MovingViolations = 'Moving Violations',
    CriminalOffense = 'Criminal Offense',
    Bankruptcy = 'Bankruptcy',
    None = 'None',
}

export enum noneValue {
    Label = 'None',
    Value = 'none',
}
