import {CookieStorage} from 'cookie-storage';

export const checkOpenedChat = (cookieStorage: CookieStorage): void => {
    const isChatOpened = cookieStorage.getItem('hs-messages-is-open');
    if (isChatOpened === 'true') {
        cookieStorage.removeItem('hs-messages-is-open');

        const expirationDate = new Date(new Date().getTime() + 30 * 60 * 1000);

        cookieStorage.setItem('hs-messages-is-open', 'false', {
            path: '/',
            ...(process.env.NODE_ENV === 'production' ? {domain: `.${window.location.hostname}`} : {}),
            secure: true,
            sameSite: 'Lax',
            expires: expirationDate,
        });
    }
};
