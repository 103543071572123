import axios from 'axios';
import {API_KEY, BASE_URL, BASE_URL_ATHENA} from '../config';

const defaultHeaders = {
    'x-api-key': API_KEY,
    'content-type': 'application/json',
    accept: 'application/json',
};

export const ZipInstance = axios.create({
    baseURL: `${BASE_URL_ATHENA}/v2/zip`,
    headers: {...defaultHeaders},
});

export const HealthInstance = axios.create({
    baseURL: `${BASE_URL}/health-quote-logic`,
    headers: {...defaultHeaders},
});

export const QuoteInstance = axios.create({
    baseURL: `${BASE_URL}/quote`,
    headers: {...defaultHeaders},
});

export const AthenaCustomerInstance = axios.create({
    baseURL: `${BASE_URL_ATHENA}/customer`,
    headers: {...defaultHeaders},
});

export const AthenaAuthInstance = axios.create({
    baseURL: `${BASE_URL_ATHENA}/auth`,
    headers: {...defaultHeaders},
});

export const AthenaInstance = axios.create({
    baseURL: `${BASE_URL_ATHENA}`,
    headers: {...defaultHeaders},
});

// makes requests to current domain
export const NonCorsInstance = axios.create({
    headers: {...defaultHeaders},
});

export const LocalInstance = axios.create({
    headers: {
        'content-type': 'application/json',
        accept: 'application/json',
    },
});
