import requestHelper from '../requestHelper';
import {ZipInstance} from '../instances';
import {checkZipCodeResponse} from './types';

export default class Phase1Api {
    static checkZipCode = async (zipCode: number | string): Promise<checkZipCodeResponse> => {
        const response = (await requestHelper(null, `/${zipCode}`, ZipInstance, 'GET')).payload as checkZipCodeResponse;

        return response;
    };
}
