import {makeAutoObservable, reaction} from 'mobx';
import {type UIStore} from '.';

import CommonApi from '../../api/phases/commonApi';
import {CUSTOMER_PORTAL_URL} from '../../config';
import {actions, categories, pushEvent} from '../../helpers/analyticsEvents';
import phaseList from '../structures/phaseList';
import {serializable} from '../sync/serialization';

const QUOTES_PHASE = 3;

class ProgressStore {
    parentStore: UIStore;

    @serializable()
    phase = 0;

    @serializable()
    percent = 0;

    @serializable()
    slide = 0;

    @serializable()
    slidesCounts = [6];

    @serializable()
    initialSlide = 0;

    @serializable()
    loading = false;

    @serializable()
    route = phaseList[0].route;

    @serializable()
    resettingQuote = false;

    constructor(parentStore: UIStore) {
        makeAutoObservable(this);
        this.parentStore = parentStore;

        reaction(
            () => this.phase,
            (cur, prev) => {
                if (cur === 3 && prev === 2) pushEvent(categories.preQuotes, actions.pageView);
            },
        );
    }

    initializeRoute(queryParams: string): void {
        this.route = `${this.route}${queryParams}`;
    }

    setSlide(slide: number): void {
        this.slide = slide;
    }

    setLoading(val: boolean): void {
        this.loading = val;
    }

    updProgressBar(progress: {percent: number; phase: number; slide: number}): void {
        const {percent, phase, slide} = progress;
        this.phase = phase;
        this.percent = percent;
        this.slide = slide;
        this.route = phaseList[!!phase ? phase - 1 : 0].route;
    }

    setProgressBar(progress: {percent?: number; phase?: number; slide?: number; route?: string}): void {
        const {percent, phase, slide, route} = progress;
        const un = (val, defaultVal) => val ?? defaultVal;
        this.phase = un(phase, this.phase);
        this.percent = un(percent, this.percent);
        this.slide = un(slide, this.slide);
        this.route = route || phaseList?.[phase]?.route || this.route;
    }

    toNextPhase(): void {
        if (this.phase == QUOTES_PHASE) {
            // if they clicked next step, direct them to customer portal with email as a parameter. This will
            // show a different text and pre-fill the email field as well.

            // Send request for validation token to Athena, wait for response, and redirect
            // Allow Athena to define the URL to provide consistency through environments
            CommonApi.getPortalRedirect(
                this.parentStore.parentStore.userData.email,
                this.parentStore.parentStore.userData.customerId,
            )
                .then((url: string) => {
                    window.location.href = url;
                })
                .catch(() => {
                    window.location.href =
                        CUSTOMER_PORTAL_URL + '?email=' + this.parentStore.parentStore.userData.email;
                });
        } else if (this.phase < phaseList.length) {
            let nextRoute = phaseList[this.phase + 1]?.route;
            const currentLocation = window.location.href.split('?');
            if (currentLocation.length === 2) {
                nextRoute += `?${currentLocation[1]}`;
            }
            if (nextRoute && !nextRoute.includes('undefined')) {
                this.setLoading(true);
                this.initialSlide = 0;
                this.slidesCounts[this.phase] = this.slide;
                this.slide = 0;
                this.phase = this.phase + 1;
                this.route = nextRoute;
                this.percent = 0.05;
            }
        }
    }

    toPreviousPhase(): void {
        if (this.phase > 0) {
            let previousRoute = phaseList[this.phase - 1]?.route;
            const currentLocation = window.location.href.split('?');
            if (currentLocation.length === 2) {
                previousRoute += `?${currentLocation[1]}`;
            }
            if (previousRoute) {
                this.setLoading(true);
                this.initialSlide = this.slidesCounts[this.phase - 1];
                this.slide = this.slidesCounts[this.phase - 1];
                this.phase -= 1;
                this.route = previousRoute;
                this.percent = 0.95;
            }
        }
    }

    toNextSlide(): void {
        this.slide = this.slide + 1;
    }

    toPreviousSlide(): void {
        this.slide = this.slide - 1;
    }
}

export default ProgressStore;
