import {NonCorsInstance} from '../instances';
import {
    ComboRequest,
    IulRequest,
    RecommendedProductResponseCombo,
    RecommendedProductResponseIul,
    RecommendedProductResponseTerm,
    RecommendedProductsRequest,
    RecommendedProductsResponse,
    TermRequest,
    ApprovalResponse,
    ApprovalRequest,
} from '@amplify/athena-models';
import {isEmpty} from 'lodash';
export default class Phase3Api {
    static sendApproveQuoteNew = async (data: ApprovalRequest): Promise<ApprovalResponse> => {
        const response = await NonCorsInstance.request({
            method: 'POST',
            url: '/api/approve',
            data,
        });

        if (response.status !== 200) {
            return null;
        }
        return !isEmpty(response.data) ? (response.data as ApprovalResponse) : null;
    };

    static getAllEligibleProducts = async (data: RecommendedProductsRequest): Promise<RecommendedProductsResponse> => {
        const response = await NonCorsInstance.request({
            method: 'POST',
            url: '/api/getEligibleProducts',
            data,
        });

        if (response.status !== 200) {
            throw new Error(`eligible products request failed with status ${response.status}`);
        }
        return response.data as RecommendedProductsResponse;
    };

    static getCombo = async (data: ComboRequest): Promise<RecommendedProductResponseCombo | null> => {
        const response = await NonCorsInstance.request({
            method: 'POST',
            url: '/api/getCombo',
            data,
        });

        if (response.status !== 200) {
            return null;
        }
        return !isEmpty(response.data) ? (response.data as RecommendedProductResponseCombo) : null;
    };

    static getTerm = async (data: TermRequest): Promise<RecommendedProductResponseTerm | null> => {
        const response = await NonCorsInstance.request({
            method: 'POST',
            url: '/api/getTerm',
            data,
        });

        if (response.status !== 200) {
            return null;
        }

        return !isEmpty(response.data) ? (response.data as RecommendedProductResponseTerm) : null;
    };

    static getIul = async (data: IulRequest): Promise<RecommendedProductResponseIul> => {
        const response = await NonCorsInstance.request({
            method: 'POST',
            url: '/api/getIul',
            data,
        });
        if (response.status !== 200) {
            return null;
        }
        return response.data as RecommendedProductResponseIul;
    };
}
