import {UpdateFunction} from '../store/UserData/types';
import updateField from './updateField';

const getField = function (fieldName: string): [unknown, UpdateFunction] {
    if (this[fieldName] === undefined) {
        return [null, () => undefined];
    }
    return [this[fieldName], updateField.call(this, fieldName)];
};

export default getField;
