import {runInAction} from 'mobx';

import {UpdateFunction} from '../store/UserData/types';

const updateField = function (fieldName: string, val?: unknown): UpdateFunction {
    if (this[fieldName] !== undefined) {
        const createUpdateFunction =
            (fieldName: string): UpdateFunction =>
            (val: unknown) =>
                runInAction(() => (this[fieldName] = val));

        const updateFunction = createUpdateFunction(fieldName);

        if (!val && typeof val !== 'string') return updateFunction;
        updateFunction(val);
    }
};

export default updateField;
