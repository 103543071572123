import requestHelper from '../requestHelper';
import {AthenaAuthInstance, LocalInstance} from '../instances';
import {portalRedirectRequest, portalRedirectResponse} from './types';

export async function updateSession<Data extends {userId: string}>(data: Data): Promise<any> {
    return LocalInstance.patch(`/api/session/${data.userId}`, {state: data});
}

export async function getPortalRedirect(email: string, customerId: string): Promise<any> {
    const body: portalRedirectRequest = {
        email: email,
        id: customerId,
    };
    return (
        (await requestHelper(body, `/portal-redirect`, AthenaAuthInstance, 'POST')).payload as portalRedirectResponse
    ).portalRedirectUrl;
}

export const CommonApi = {
    updateSession,
    getPortalRedirect,
};

export default CommonApi;
